import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Singles from 'src/components/OurProducts/Singles'
import SEO from 'src/components/shared/SEO'
import Category from 'src/components/Products/category'
import SupplementsBg from '../../assets/img/categories/supplements.png';

const SEO_CONFIG = {
  title: 'Supplements | Finn',
  description:
    'Shop all the products that Finn has to offer. We formulate pet supplements that combine trusted research and modern wellness. Get trusted nutrition with Finn.',
  keywords: ['Supplements']
}

const ProductsPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <Category title="Supplements" image={SupplementsBg}>
          <Singles isProductLibrary={true} />
        </Category>
      </Layout>
    </>
  )
}

export default ProductsPage
